import React from "react";
import { Link } from "react-router-dom";
import Github from "remixicon-react/GithubFillIcon";
import Linkedin from "remixicon-react/LinkedinBoxFillIcon";
import { useLanguage } from "../../context/LanguageContext";
import { translate } from "../../context/translate";
import { useLocation } from "react-router-dom";

const NavigationBar = () => {
  const { language } = useLanguage();
  const location = useLocation();
  return (
    <div className="flex py-4 flex-wrap justify-between sm:px-10">
      <div className="flex items-center">
        <Link
          to="/"
          className={`mr-8 ${location.pathname === "/" ? "underline" : ""}`}
        >
          {translate("home", language)}
        </Link>
        <Link
          to="/about"
          className={`mr-8 ${
            location.pathname === "/about" ? "underline" : ""
          }`}
        >
          {translate("about", language)}
        </Link>
        <Link
          to="/article"
          className={`mr-8 ${
            location.pathname === "/article" ? "underline" : ""
          }`}
        >
          {translate("articles", language)}
        </Link>
      </div>
      <div className="flex items-center mt-4 sm:mt-0">
        <a
          href="https://www.linkedin.com/in/dimas7/"
          className="mr-4"
          target="_blank"
        >
          <Linkedin size={30} />
        </a>
        <a href="https://github.com/dimas7git" target="_blank">
          <Github size={30} />
        </a>
        <div className="ml-4">
          <a
            href="mailto:dimas7.comunicacao@gmail?subject=Contato%20via%20Portfólio&body=Olá!"
            className="py-2 px-4 bg-gray-600 text-white text-sm rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {translate("contactMe", language)}
          </a>
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
