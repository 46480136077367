import React, { useState, useEffect } from 'react';
import '../../tailwind.css';
import { useLanguage } from '../../context/LanguageContext';
import { translate } from '../../context/translate';
import profilePhoto from '../../images/perfil-link.png';
import TypingEffect from './TypingEffect';
import TypeEffect from './TypeEffect';

const Headline = () => {
  const { language } = useLanguage();
  const [showEmail, setShowEmail] = useState(false); 

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowEmail(true);
    }, 3500); 

    return () => clearTimeout(timer); 
  }, []);

  return (
    <div className="flex flex-wrap px-6 py-4 mt-10 sm:px-10">
      <img src={profilePhoto} alt="Profile" className="w-[200px] h-[200px] object-cover rounded-full" />

      <div className="flex-1 place-content-center ml-10">
        <TypingEffect text={translate('welcome', language)} />
        <TypeEffect text={translate('im', language)} />

        {showEmail && (
          <span className="text-xl text-secondary">dimas7.comunicacao@gmail.com</span>
        )}
      </div>
      <div className="flex">
        <p className="text-2xl text-secondary place-content-center">
          {translate('passion', language)} <br /> {translate('cyberSecurity', language)}
        </p>
      </div>
    </div>
  );
};

export default Headline;
