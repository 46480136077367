import React from 'react';
import { translate } from '../../context/translate';
import { useLanguage } from '../../context/LanguageContext';

const Article = () => {
    const { language } = useLanguage();
    return (
        <div className="flex items-center justify-center h-screen">
            <div className="container mx-auto text-center">
                <p className="text-2xl font-medium text-gray-700">
                    {translate('article', language)}
                </p>
            </div>
        </div>
    );
}

export default Article;
