import enTranslations from '../locales/en.json';
import ptTranslations from '../locales/pt.json';

const translations = {
  en: enTranslations,
  pt: ptTranslations,
};

export const translate = (key, language) => {
  const languageTranslations = translations[language] || translations['en'];
  return languageTranslations[key] || key;
};
