import React from 'react';
import Project from './index';
import '../../tailwind.css';
import { useLanguage } from '../../context/LanguageContext';
import { translate } from '../../context/translate';

const ProjectsContainer = () => {
  const { language } = useLanguage();

  return (
    <div className='px-6 py-4 mt-2 '>
      <div className='border-t-2 border-border'>
        <h1 className='py-10 text-3xl font-bold'>{translate('projects', language)}</h1>
        <div className='flex flex-wrap justify-center'>
          <Project
            title={translate('psPenTitle', language)}
            description={translate('psPenDescription', language)}
            tags={['C#']}
            links={{ youtube: '', github: 'https://github.com/dimas7git/TCC-EnsinoMedio-IFSP' }}
          />
          <Project
            title={translate('taskProjectManagerTitle', language)}
            description={translate('taskProjectManagerDescription', language)}
            tags={['JavaScript', 'Java', 'Spring Boot']}
            links={{ youtube: '', github: 'https://github.com/dimas7git/TaskProjectManager' }}
          />
          <Project
            title={translate('crudFinanceTrackerTitle', language)}
            description={translate('crudFinanceTrackerDescription', language)}
            tags={['Node', 'JavaScript', 'Postgresql']}
            links={{ youtube: '', github: 'https://github.com/dimas7git/CRUD-Finance-Tracker' }}
          />
          <Project
            title={translate('networkTrafficAnalyzerTitle', language)}
            description={translate('networkTrafficAnalyzerDescription', language)}
            tags={['Python']}
            links={{ youtube: '', github: 'https://github.com/dimas7git/NetworkTrafficAnalyzer' }}
          />
          <Project
            title={translate('cashFlowTitle', language)}
            description={translate('cashFlowDescription', language)}
            tags={['JavaScript', 'React Native']}
            links={{ youtube: '', github: 'https://github.com/dimas7git/CashFlow' }}
          />
          <Project
            title={translate('barberTitle', language)}
            description={translate('barberDescription', language)}
            tags={['JavaScript', 'TypeScript', 'Next.js']}
            links={{ youtube: '', github: 'https://github.com/dimas7git/BarberShop' }}
          />
          <Project
            title={translate('classTitle', language)}
            description={translate('classDescription', language)}
            tags={['Java']}
            links={{ youtube: '', github: 'https://github.com/dimas7git/ClassMaster' }}
          />
          <Project
            title={translate('astraTitle', language)}
            description={translate('astraDescription', language)}
            tags={['Java', 'PHP']}
            links={{ youtube: '', github: 'https://github.com/dimas7git/ProjetoIntegrador-VersaoJava-EnsinoMedio-IFSP' }}
          />
          <Project
            title={translate('dataScienceTitle', language)}
            description={translate('dataScienceDescription', language)}
            tags={['Python', 'MachineLearning']}
            links={{ youtube: '', github: 'https://github.com/dimas7git/data-science-nba-graphs' }}
          />
        </div>
      </div>
    </div>
  );
}

export default ProjectsContainer;
