import { motion } from 'framer-motion';

function TypingEffect({ text }) {
  const splitText = text.split(''); 

  return (
    <h1 className="text-6xl font-bold mb-1">
      {splitText.map((char, index) => (
        <motion.span
          key={index}
          initial={{ opacity: 0 }} 
          animate={{ opacity: 1 }} 
          transition={{ delay: index * 0.1, duration: 0.1 }}           
        >
          {char}
        </motion.span>
      ))}
    </h1>
  );
}

export default TypingEffect;
