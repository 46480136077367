import React from 'react';
import { useLanguage } from '../../context/LanguageContext';

const LanguageSwitcher = () => {
  const { language, changeLanguage } = useLanguage();

  return (
    <div className="flex justify-end p-4 mr-5">
      <button
        onClick={() => changeLanguage('en')}
        className={`p-2 ${language === 'en' ? 'font-bold' : ''}`}
      >
        English
      </button>
      <button
        onClick={() => changeLanguage('pt')}
        className={`p-2 ${language === 'pt' ? 'font-bold' : ''}`}
      >
        Português
      </button>
    </div>
  );
};

export default LanguageSwitcher;
