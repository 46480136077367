import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';

function TypingEffect({ text }) {
  const splitText = text.split(''); 

  const [cursorVisible, setCursorVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCursorVisible(true); 
    }, splitText.length * 100 + 1000);

    return () => clearTimeout(timer); 
  }, [splitText.length]); 

  return (
    <motion.h1
      className="text-6xl mb-1"
      initial={{ opacity: 0 }}  
      animate={{ opacity: 1 }}  
      transition={{ delay: 1, duration: 1 }} 
    >
      {splitText.map((char, index) => (
        <motion.span
          key={index}
          initial={{ opacity: 0 }}  
          animate={{ opacity: 1 }} 
          transition={{
            delay: 1 + index * 0.1,
            duration: 0.1 
          }}
        >
          {char}
        </motion.span>
      ))}
      
      {cursorVisible && <span className="typing-cursor"></span>}
    </motion.h1>
  );
}

export default TypingEffect;
